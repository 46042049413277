import React from 'react'
import { Link } from 'gatsby'
//scss
import '../scss/page/contact.scss'
const Success = () => {
  return (
    <div className='success'>
      <div className='container'>
        <h1>Votre mail a bien été envoyé !</h1>
        <p>Nous vous recontacterons très prochainement.</p>
        <ul>
          <li>
            <Link to={`/`} className='button'>
              Retour à la page d'accueil
            </Link>
          </li>
          <li>
            <Link to={`/produits`} className='button second'>
              Voir les produits
            </Link>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Success
